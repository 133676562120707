<template>
  <div id="b2c" class="main-body">
    <div id="nav-white" />
    <div id="services-front" class="chartered-bg">
      <h1 class="zoomIn">Chartered Truck Service</h1>
    </div>

    <div id="content">
      <h1 class="fadeIn">Brief Summary</h1>
      <p class="fadeIn">
        We provide customized Chartered Truck Services to meet your specific
        cold chain logistics needs. Whether it’s personalized route planning,
        flexible scheduling, or strict temperature control, GoLog’s Chartered
        Truck Services ensure that every transport operation is tailored to fit
        your business requirements. This service is ideal for bulk
        transportation, special event deliveries, or urgent orders requiring
        quick turnaround, ensuring efficiency and reliability in your logistics
        operations.
      </p>
    </div>

    <visit-system
      name="B2BPRO"
      link="https://b2bpro.golog.my/login"
      :is-b2b="true"
      class="fadeIn"
    />
  </div>
</template>
<script>
import intersectionObserverMixin from "@/mixins/intersectionObserverMixin";
import VisitSystem from "@/components/VisitSystem.vue";

export default {
  components: { VisitSystem },
  mixins: [intersectionObserverMixin],
  mounted() {
    this.setupIntersectionObserver(".fadeIn", 0.5);
    this.setupIntersectionObserver(".zoomIn", 0.1);
  },
};
</script>
